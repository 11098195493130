class MegaDropdown {
    static SELECTORS = {
        header: '#js-header',
        listItems: '.header__list-item',
        navList: '.header__nav-list',
        dropdownArea: '.header__dropdown-area',
        dropDown: '.header__drop-down',
    };

    static CLASSES = {
        isActive: 'is-active',
        isBg: 'is-bg',
        slowTransition: 'slow-transition',
    };

    constructor() {
        this.theme_url = '/wp-content/themes/kitanihon';
        this.switchDelay = 700;
        this.hoverDelay = 900; // ホバー待機時間を0.9秒に設定

        this.state = {
            isAnyMenuOpen: false,
            activeMenu: null,
            activeItem: null,
            hoverTimer: null,
        };

        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.hideMenu = this.hideMenu.bind(this); // ← ここ重要

        document.addEventListener('DOMContentLoaded', () => this.init());
    }

    init() {
        this.elements = {
            header: document.querySelector(MegaDropdown.SELECTORS.header),
            listItems: document.querySelectorAll(MegaDropdown.SELECTORS.listItems),
            navList: document.querySelector(MegaDropdown.SELECTORS.navList),
        };

        this.bindEvents();
    }

    bindEvents() {
        this.elements.listItems.forEach((item) => {
            item.addEventListener('mouseenter', this.handleMouseEnter);
            item.addEventListener('mouseleave', this.hideMenu); // ← 即時閉じる
        });

        this.elements.navList.addEventListener('mouseleave', () => {
            this.clearHoverTimer();
            this.setHoverTimer(this.hideMenu);
        });
    }

    clearHoverTimer() {
        if (this.state.hoverTimer) {
            clearTimeout(this.state.hoverTimer);
            this.state.hoverTimer = null;
        }
    }

    setHoverTimer(callback, target = null) {
        this.state.hoverTimer = setTimeout(() => {
            callback.call(this, target);
        }, this.hoverDelay);
    }

    showMenu(item) {
        const menu = item.querySelector(MegaDropdown.SELECTORS.dropdownArea);
        const dropDown = item.querySelector(MegaDropdown.SELECTORS.dropDown);

        if (!menu || !dropDown) return;

        this.state.activeMenu = menu;
        this.state.activeItem = dropDown;

        setTimeout(() => {
            this.elements.header.classList.remove(MegaDropdown.CLASSES.slowTransition);
        }, this.switchDelay);

        this.elements.header.classList.add(MegaDropdown.CLASSES.isBg);
        menu.classList.add(MegaDropdown.CLASSES.isActive);
        dropDown.classList.add(MegaDropdown.CLASSES.isActive);
        this.state.isAnyMenuOpen = true;
    }

    hideMenu() {
        this.clearHoverTimer();

        if (!this.state.activeMenu || !this.state.activeItem || !this.state.isAnyMenuOpen) return;

        this.elements.header.classList.add(MegaDropdown.CLASSES.slowTransition);
        this.elements.header.classList.remove(MegaDropdown.CLASSES.isBg);

        this.state.activeMenu.classList.remove(MegaDropdown.CLASSES.isActive);
        this.state.activeItem.classList.remove(MegaDropdown.CLASSES.isActive);

        this.state.activeMenu = null;
        this.state.activeItem = null;
        this.state.isAnyMenuOpen = false;

        setTimeout(() => {
            this.elements.header.classList.remove(MegaDropdown.CLASSES.slowTransition);
        }, this.switchDelay);
    }

    handleMouseEnter(event) {
        this.clearHoverTimer();
        this.setHoverTimer(() => {
            this.hideMenu();
            this.showMenu(event.target);
        });
    }
}

// Initialize the class
new MegaDropdown();
